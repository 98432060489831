/* Navbar Styles */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #1f1c2c; /* Deep purple */
    padding: 15px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    color: #ffd700; /* Gold text */
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  
  .menu-toggle {
    font-size: 1.5rem;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    display: none;
  }
  
  .navbar-menu {
    display: flex;
    list-style: none;
    gap: 20px;
  }
  
  .navbar-menu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #1f1c2c; /* Match navbar background */
    padding: 20px 0;
    border-top: 1px solid #444;
  }
  
  .navbar-item {
    text-align: center;
  }
  
  .navbar-link {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .navbar-link:hover {
    color: #ffd700; /* Gold on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    .navbar-menu {
      display: none;
    }
  
    .navbar-menu.active {
      display: flex;
    }
  }
  