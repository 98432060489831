/* Projects Section Styles */
.projects {
    padding: 60px 20px;
    background-color: #1c1e2e; /* Deep background color */
    color: #fff;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .projects h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #ffd700; /* Gold for headings */
    text-transform: uppercase;
  }
  
  .projects h2 span {
    color: #f44336; /* Accent color */
  }
  
  .project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 30px;
  }
  
  .project-card {
    background: #292a40; /* Dark card background */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-card h3 {
    font-size: 1.5rem;
    color: #ffd700; /* Gold */
    margin-bottom: 10px;
  }
  
  .project-card p {
    color: #d3d3d3;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .project-card a {
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    background: linear-gradient(to right, #ffd700, #f44336); /* Gradient button */
    color: #fff;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .project-card a:hover {
    transform: translateY(-5px);
    background: linear-gradient(to right, #f44336, #ffd700);
  }
  
  .project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
  
  @media (max-width: 768px) {
    .projects h2 {
      font-size: 2rem;
    }
  
    .project-card {
      padding: 15px;
    }
  }
  