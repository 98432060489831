/* Main Section Style */
.social-media {
    padding: 80px 20px;
    background: linear-gradient(135deg, #1f1c2c, #928DAB); /* Deep purple to light purple gradient */
    color: #ffffff; /* White text for contrast */
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .social-media h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffd700; /* Gold accent color */
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
  }
  
  .social-media h2 span {
    color: #f44336; /* Red accent */
  }
  
  .social-media p {
    font-size: 1.1rem;
    margin-bottom: 40px;
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
  }
  
  /* Social Icons Container */
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .social-link {
    font-size: 3rem;
    color: #ffd700; /* Gold icons */
    background-color: #222; /* Dark background for icons */
    padding: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Deep shadow */
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .social-link:hover {
    transform: scale(1.2); /* Slight scale effect on hover */
    color: #ffffff;
    background-color: #f44336; /* Red background on hover */
  }
  
  .social-link i {
    transition: color 0.3s ease;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .social-link {
      font-size: 2.5rem;
      padding: 15px;
    }
  }
  